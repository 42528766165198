.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Modifiez la hauteur pour occuper toute la hauteur de la fenêtre */
    flex-direction: column; /* Ajoutez cette ligne pour centrer l'image verticalement */
  }
  
img {
    height: 250px;
    width: auto;
}