/* Style de base pour écrans larges */
.logo {
  background-image: url('../../public/assets/images/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 35%;
  height: 20vh;
}

.grecaptcha-badge {
  display:none !important;
}

input.custom-input {
  border: 3px solid #E6F485;
  border-radius: 15px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  background-color: #f1f1f1;
  color: #282828; /* Couleur du texte */
}

input.custom-input-error {
  border: 3px solid #F08A81;
}

/*
input.custom-input:focus {
  outline: none;
  border-color: #1a1a1a;
}
*/

.form-control:focus {
  border-color: #000000; /* Couleur de la bordure lorsque l'élément est en focus */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Ombre légère */
  background-color: #f1f1f1; /* Couleur d'arrière-plan */
  color: #282828; /* Couleur du texte */
}

.custom-title {
  font-size: 96px;
  font-weight: 600;
  color: #E6F485;
  height: 82px;
  overflow: hidden;
}

.custom-subtitle {
  font-size: 31px;
  color: #fff;
  font-weight: 300;
  margin-top: -20px;
  margin-left: 4px;
}

.custom-registration-condition {
  font-size: 12px;
  color: #fff;
}

.loading-dots {
  font-size: 24px;
  animation: bounce 1s infinite;
}

.bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.bubble.hovered {
  transform: scale(1.2);
}

.custom-button-rounded {
  background-color: #E6F485;
  border: none; /* Retirer la bordure pour obtenir un bouton sans bordure */
  height: 50px;
  width: 50px;
  padding: 5px;
  border-radius: 50%; /* Utilisez 50% pour obtenir un cercle */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Au lieu de margin-left: auto; margin-right: auto; */
  text-align: center;
  line-height: 1; /* Utilisez 1 pour centrer verticalement le contenu */
}

.custom-toast-success {
  background-color: #E6F485;
  color: #282828;
  flex-direction: row;
  border-radius: 10px; /* Ajoute des bordures arrondies */
  align-items: flex-end;
}

.custom-toast-error {
  background-color: #F08A81;
  color: #282828;
  flex-direction: row;
  border-radius: 10px; /* Ajoute des bordures arrondies */
  align-items: flex-end;
}

.custom-toast-container-image {
  flex: 1; /* Laisse l'image occuper tout l'espace disponible */
  margin-left: -15px;
  position: absolute;
  bottom: 0;
}

.custom-toast-image {
  top: 13%;
  height: 79px;
  width: 86px;
  object-fit: cover;
}

.custom-toast-container-text {
  flex: 2; /* Laisse le texte occuper deux fois l'espace de l'image */
  margin-left: 5px;
}

.custom-toast-title {
  color: #282828;
  font-weight: 700;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif!important;
}

.custom-toast-message {
  color: #282828;
  font-size: 13.5px;
  font-family: 'DM Sans', sans-serif!important;
}

/* Styles pour la modal */
.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  z-index: 9999; /* Assure que la modal est au-dessus de tout le reste */
  box-shadow: 0 2px 10px #E6F485;
  width: 500px;
}

.custom-modal-header {
  background: rgb(33, 33, 33);
  box-shadow: 0 2px 10px #E6F485;
  border-radius: 8px 8px 0px 0px;
  height: 60px;
  padding: 10px;
  font-size: 18px;
}

.custom-modal-body {
  background: #fff;
  box-shadow: 0 2px 10px #E6F485;
  color: #000;
  border-radius: 0px 0px 8px 8px;
  padding: 10px;
  font-size: 13px;
}

/* Styles pour l'overlay */
.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 9998; /* Assure que l'overlay est en dessous de la modal */
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Ajoutez des règles de média queries pour rendre le site responsive */
@media (max-width: 768px) {
  .logo {
    width: 65%;
    height: 15vh;
  }
  input.custom-input {
    font-size: 14px;
    padding: 15px;
    font-size: 16px;
    margin-top: -3%;
  }
  .custom-title {
    font-size: 60px;
    height: 66px;
    height: 52px;
  }
  .custom-subtitle {
    font-size: 19.5px;
    margin-top: -15px;
    margin-left: 2.5px;
  }
  .custom-registration-condition {
    font-size: 11.5px;
  }
  .custom-copyright {
    font-size: 10px;
    font-weight: 550;
  }
  .loading-dots {
    font-size: 20px;
  }

  .custom-toast-success {
    margin: 10px 40px;
  }

  .custom-toast-error {
    margin: 10px 40px;
  }
}