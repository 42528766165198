/* Styles pour le bouton */
button.custom-button {
    display: flex; /* Utilisez un conteneur flex pour centrer l'icône et le texte */
    justify-content: center; /* Centre horizontalement le contenu */
    align-items: center; /* Centre verticalement le contenu */
    background-color: #E6F485;
    color: #282828;
    border: 2px solid #E6F485;
    border-radius: 15px;
    padding: 12px;
    font-size: 20.6px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
}

/* Styles au survol du bouton */
button.custom-button:hover {
    background-color: #E6F485;
    border-color: #E6F485;
    color: #282828;
}

/* Styles lorsque le bouton est actif (clic) */
button.custom-button:active {
    background-color: #E6F485;
    border-color: #E6F485;
}

.custom-button-icon {
    margin-right: 8px; /* Espacement entre l'icône et le texte, ajustez selon vos besoins */
}

.custom-button-loading-icon {
    width: 31px; /* Modifiez la largeur selon vos besoins */
    height: 31px; /* Modifiez la hauteur selon vos besoins */
}
