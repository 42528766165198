.modal-slide-from-bottom {
    position: fixed;
    bottom: -100%;
    height: 80%;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: bottom 0.3s ease-in-out;
    z-index: 1000;
    color: #282828;
  }
  
  .modal-slide-from-bottom.open {
    bottom: 0;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 30px;
    width: 50%;
    border-radius: 15px 15px 0 0;
    position: relative;
    height: 100%;
  }

  .modal-content .custom-modal-title {
    color: #282828;
    font-weight: 700;
    font-size: 24px;
    font-family: 'DM Sans', sans-serif!important;
    margin-bottom: 20px;
    margin-top: -15px;
  }

  .modal-content .custom-modal-subtitle {
    display: inline;
    background-color: #282828;
    color: #E6F485;
    font-weight: 600;
    font-size: 19px;
    font-family: 'DM Sans', sans-serif!important;
    text-transform: uppercase;
    padding: 5px;
  }  

  .modal-content .custom-modal-text {
    color: #282828;
    font-size: 19px;
    font-family: 'DM Sans', sans-serif!important;
    margin-top: 0;
    margin-bottom: 5px!important;
  }

  .modal-content a {
    color: #282828;
    text-decoration: none;
  }

  .close-button {
    background-color: transparent;
    color: #282828;
    border: none;
    padding: 8px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }

  @media (max-width: 768px) {
    .modal-content {
      width: 100%!important;
    }
  }
  